import React, { PureComponent, Fragment, useState } from 'react';
import {
    LineChart, Line, XAxis, ReferenceArea, YAxis, CartesianGrid, Tooltip, Legend, Area,AreaChart,Bar,
    ResponsiveContainer, ComposedChart, ReferenceLine,
    Cell, Brush
} from 'recharts';
import PropTypes from "prop-types";
import { getString } from "../../../utils/translations";
import styled from 'styled-components'
import _ from 'lodash'
import { getColor } from '../../../views/Dashboard/TableData/utils';
import colors from "../../../styles/colors";
import {OVER_TIME_MODULES_BUTTONS, OVER_TIME_EMOTIONS_BUTTONS, OVER_TIME_BUTTONS} from "../../../constants"
import { getEmotionRanger } from "../../../utils/charts"

import { getAsset } from '../../../utils';
import moment from "moment";
import {
    PITCH_ANGLE_MAX_ALERT_THRESHOLD, PITCH_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    YAW_ANGLE_MIN_ALERT_THRESHOLD
} from "../../../api/remoteConfig";


const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value, emotion } = props;
    console.log("CustomizedDot", { payload, value, emotion })
    if(payload && payload.metadata === "empty"){
        return null;
    }
/*    if(!payload[emotion] && payload[emotion] !== 0){
        return null;
    }*/


    let value1 = value.length > 1 ? value[1] : value;
    if(!value1 && value1 !== 0){
        return null;
    }
    const color = getColor(emotion, value1)
    return (
        <circle
            key={cx}
            cx={cx}
            cy={cy}
            r={4}
            stroke={color}
            fill={color}
        />
    );
};

const addRangeToData = (data, emotion) => {
    let emotionRanges = getEmotionRanger(emotion)
    data.forEach((data) => {
        if ((Object.values(OVER_TIME_EMOTIONS_BUTTONS).includes(emotion)
            || emotion === OVER_TIME_MODULES_BUTTONS.ENERGY)) {
            return
        }
        data.high = (1 - emotionRanges.medium) * 100
        data.medium = (emotionRanges.medium - emotionRanges.low) * 100
        data.low = (emotionRanges.low) * 100
    });
}

const annotationFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD")
}

const CustomizedLabel = (props) => {

    const { openDeleteAnnotationPopup, openEditAnnotationPopup, annotation } = props
    const { x, width } = props.viewBox;
    const [showIcons, setShowIcons] = useState(false)


    return <foreignObject width={width } height={45} x={x } y={-40}>
        <div onMouseEnter={() => { setShowIcons(true) }} onMouseLeave={() => { setShowIcons(false) }} style={{
            width: "100%", height: "100%", background: colors.lipstick, position: 'relative',
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
            <text style={{ color: colors.white, fontWeight: 600, fontSize: 13, textAlign: 'center' }}>{trimText(annotation.name, 20)}</text>
            {showIcons && <Fragment>
                <img src={getAsset("icons/mini_delete.svg")}
                    onClick={() => { openDeleteAnnotationPopup(annotation) }}
                    style={{ position: 'absolute', left: 3, top: 3, cursor: 'pointer' }} />
                <img src={getAsset("icons/mini_edit.svg")}
                    onClick={() => { openEditAnnotationPopup(annotation) }}
                    style={{ position: 'absolute', right: 3, top: 3, cursor: 'pointer' }} />
            </Fragment>
            }
        </div>
    </foreignObject>
}

function trimText(text, threshold) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
}

const getDataRange = (data, xKey) => {
    let max = _.get(_.maxBy(data, xKey), xKey, null)
    let min = _.get(_.minBy(data, xKey), xKey, null)

    return {
        min,
        max
    }
}

const setAnnotationInRange = (annotation, range) => {
    let start = new Date(annotation.startDate);
    let end = new Date(annotation.endDate);

    let rangeStart = new Date(range.min);
    let rangeEnd = new Date(range.max);

    if(start < rangeStart && end <= rangeEnd){
        start = rangeStart
    }

    if(start < rangeEnd && start >= rangeStart && end >rangeEnd){
        end = rangeEnd
    }

    if(start < rangeStart && end > rangeEnd){
        start = rangeStart
        end = rangeEnd
    }

    return {
        startDate: annotationFormatter(start),
        endDate: annotationFormatter(end)
    }
}


export default class OverTimeChart extends PureComponent {

    handleOnChange = (event) => {
        const { sync, setZoomData } = this.props;
       console.log("handleOnChange", event)
        if(sync){
            const startIndex = event.startIndex;
            const endIndex = event.endIndex;
            setZoomData(startIndex, endIndex)
        }

    }

    renderAnnotations = () => {
        const { showAnnotations, annotations, domain, data,  openDeleteAnnotationPopup, openEditAnnotationPopup } = this.props
        let {min, max} = getDataRange(data, "date")
        return showAnnotations && annotations.map(a => {
            let {startDate, endDate} = setAnnotationInRange(a, {min, max})
            let x1 = annotationFormatter(startDate);
            let x2 = annotationFormatter(endDate);

            return [
                <ReferenceLine
                    x={x1}
                    stroke={colors.lipstick}
                    strokeDasharray="3 3"
                    strokeWidth={2}
                />,
                <ReferenceLine
                    x={x2}
                    stroke={colors.lipstick}
                    strokeDasharray="3 3"
                    strokeWidth={2}
                />,
                <ReferenceArea
                    x1={x1}
                    x2={x2}
                    y1={100}
                    fill={colors.lipstick}
                    fillOpacity={1}
                    label={<CustomizedLabel openDeleteAnnotationPopup={openDeleteAnnotationPopup}
                        openEditAnnotationPopup={openEditAnnotationPopup}
                        annotation={a} />} />
            ]
        })
    }

    render() {
        const { data, x, y, selectedEmotions, tooltipLabelFormatter, domain, hideTicks,
            extraData, showBar, scores,
            showRangePicker = true,
            sync, startIndex, endIndex,
            showHorizontalLines = false,
            topLabel = false,
            selectedIds
        } = this.props;

        let dataWithRange = _.cloneDeep(data)
        if(extraData){
            dataWithRange = _.orderBy([...data, ...extraData], (i)=>{return new Date(i[x.key])})
        }

        addRangeToData(dataWithRange, selectedEmotions[0])

        let {min, max} = getDataRange(data, x.key)

        //console.log("selectedIds", {selectedIds, selectedEmotions, dataWithRange})

        return (
            <Fragment>
                {(_.get(dataWithRange, "length", 0)) ? <ResponsiveContainer width="100%" height="90%">
                    <ComposedChart
                        width={500}
                        height={"100%"}
                        data={dataWithRange}
                        style={{}}
                        syncId={sync ? "overTimeChart" : null}
                    >
                        {showHorizontalLines && (<CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />)}
                        <CartesianGrid horizontal={!showBar} vertical={!showBar} strokeDasharray="3 3" />
                        <XAxis dataKey={x.key} tickFormatter={x.formatter || undefined} />
                        <YAxis dataKey={y.key} domain={domain} allowDataOverflow={false} hide={hideTicks} />
                        <Tooltip
                            content={<CustomTooltip tooltipLabelFormatter={tooltipLabelFormatter} data={dataWithRange} scores={scores} x={x} />} />

                        {this.renderAnnotations()}

                        {
                            selectedIds ? selectedIds.map((id, index) => {


                                return selectedEmotions.map(emotion => {
                                    let elements = [
                                        <Area type="monotone"
                                              dataKey={`${emotion}_${id}`}
                                              stroke={colors.blue}
                                              strokeWidth={1.7}
                                              fill={colors.gray}
                                              fillOpacity={0.1}
                                              isAnimationActive={false}
                                              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                                              dot={<CustomizedDot emotion={emotion} />}
                                        />
                                    ];

                                    if(selectedEmotions.includes(OVER_TIME_BUTTONS.YAW_ANGLE)){
                                        elements.push((
                                            <ReferenceArea y1={-50} y2={YAW_ANGLE_MIN_ALERT_THRESHOLD} fill={colors.tomato}/>
                                        ))
                                        elements.push((
                                            <ReferenceArea y1={YAW_ANGLE_MIN_ALERT_THRESHOLD} y2={YAW_ANGLE_MAX_ALERT_THRESHOLD} fill={colors.interest}/>
                                        ))
                                        elements.push((
                                            <ReferenceArea y1={YAW_ANGLE_MAX_ALERT_THRESHOLD} y2={50} fill={colors.tomato}/>
                                        ))
                                    }

                                    if(selectedEmotions.includes(OVER_TIME_BUTTONS.PITCH_ANGLE)){
                                        elements.push((
                                            <ReferenceArea y1={-60} y2={PITCH_ANGLE_MIN_ALERT_THRESHOLD} fill={colors.tomato}/>
                                        ))
                                        elements.push((
                                            <ReferenceArea y1={PITCH_ANGLE_MIN_ALERT_THRESHOLD} y2={PITCH_ANGLE_MAX_ALERT_THRESHOLD} fill={colors.interest}/>
                                        ))
                                        elements.push((
                                            <ReferenceArea y1={PITCH_ANGLE_MAX_ALERT_THRESHOLD} y2={60} fill={colors.tomato}/>
                                        ))
                                    }
                                    return elements
                                })
                            }) : selectedEmotions.map(emotion => {
                                let scoreEmotion = emotion === OVER_TIME_MODULES_BUTTONS.MOOD ? "valence" : emotion

                                return [
                                    showBar ? (
                                        <Bar dataKey={emotion}
                                             stroke={colors.black}
                                             strokeWidth={1}
                                             fill={colors.gray}
                                             fillOpacity={0.5}
                                             isAnimationActive={false}
                                             label={topLabel ? { position: 'top' } : null}
                                        >
                                            {dataWithRange.map((entry, index) => {
                                                const color = getColor(emotion, entry[emotion],false, entry)
                                                return <Cell key={`cell-${index}`} fill={color} />
                                            })}
                                        </Bar>
                                    ) : (
                                        <Area
                                            type="monotone"
                                            dataKey={emotion}
                                            stroke={colors.blue}
                                            strokeWidth={1.7}
                                            fill={colors.gray}
                                            fillOpacity={0.1}
                                            isAnimationActive={false}
                                            dot={<CustomizedDot emotion={emotion} />}
                                        />
                                    )
                                    ,
                                    scores && scores[scoreEmotion] ? (
                                        <ReferenceLine y={scores[scoreEmotion] * 100} label={getString("house_avg")} stroke="black" strokeDasharray="3 3" />
                                    ) : null

                                ]
                            })
                        }


                        {showRangePicker && (<Brush onChange={this.handleOnChange}/>)}
                    </ComposedChart>
                </ResponsiveContainer> :
                    <NoDataMessage>
                        {getString('empty_overtime_chart')}
                    </NoDataMessage>
                }
            </Fragment>
        );
    }
}


const CustomTooltip = ({ active, payload, label, tooltipLabelFormatter, data, x, scores }) => {
   // console.log("payload.metadata", { active, payload, label, tooltipLabelFormatter, data, x })
    if(payload && payload[0] && payload[0].payload.metadata === "empty"){
       // console.log("return null")
        return null;
    }
    if (active && payload && payload.length && payload[0].name !== "foo") {
        let score = null;
        if(scores && scores[payload[0].name]){
            score = getScore(payload[0].value, scores[payload[0].name])

        }

         //console.log("payload.metadata", { active, payload, label, tooltipLabelFormatter, data, x })

        if(payload[0].dataKey === "pricingUnits"){
            let dateToShow = payload[0].payload.date
            let unitPrice = null
            let totalPrice = null
            let currency = "$"
            if(payload[0].payload.type === "month"){
              //  console.log("month tooltip", payload[0].payload)
               // unitPrice = payload[0].payload.unitPrice
                if(payload[0].payload.currency === "jpy"){
                    currency = "¥"
                }
                totalPrice = payload[0].payload.totalPrice.toFixed(2)
            }else{
                dateToShow = moment(dateToShow).format("DD.MM.YYYY");
            }

            return (
                <div className="custom-tooltip"
                     style={{ backgroundColor: 'white', border: 'thin solid #999999', padding: 12, fontWeight: "400" }}>

                    <div>{tooltipLabelFormatter(label, data, x)}</div>
                    <div style={{ color: colors.blue }}>{`${getString("date")}: ${dateToShow}`}</div>
                    <div style={{ color: colors.blue }}>{`${getString("sessions")}: ${payload[0].payload.sessions}`}</div>
                    <div style={{ color: colors.blue }}>{`${getString("units")}: ${payload[0].value}`}</div>
                    {unitPrice ? <div style={{ color: colors.blue }}>{`${getString("unitPrice")}: ${unitPrice}`}</div> : null}
                    {totalPrice ? <div style={{ color: colors.blue }}>{`${getString("totalPrice")}: ${currency}${totalPrice}`}</div> : null}
                </div>
            );
        }

        const count = _.get(payload, "[0].payload.count", 0)
        return (
            <div className="custom-tooltip"
                style={{ backgroundColor: 'white', border: 'thin solid #999999', padding: 12, fontWeight: "400" }}>
                <div>{tooltipLabelFormatter(label, data, x)}</div>
                <div style={{ textTransform: 'uppercase', color: colors.blue }}>{`${payload[0].name}: ${payload[0].value}`}</div>
                {score}
                {count ? <div style={{ textTransform: 'uppercase', color: colors.blue }}>{`${count} ${getString("entries")}`}</div> : null}
            </div>
        );
    }

    return null;
};

const getScore = (value, avg) => {

    let normalizedAvg = avg * 100

    let props = null

    function percentage(partialValue, totalValue) {
        if(totalValue === 0){
            return 0;
        }
        return Math.round((100 * partialValue) / totalValue) - 100;
    }

    if(value > normalizedAvg){
        props = {
            color: colors.green,
            sign: "+",
            value: percentage(value, normalizedAvg)
        }
    }

    if(value < normalizedAvg){
        props = {
            color: colors.red,
            sign: "-",
            value: percentage(normalizedAvg, value)
        }
    }

    return props ? (<div style={{ textTransform: 'uppercase', color: props.color }}>{`${props.sign}${props.value}%`}</div>) : null

}

const NoDataMessage = styled.div`
                display: flex;
                height: 100%;
                width: 100%;
                text-align: center;
                justify-content: center;
                padding: 29px;
                font-family: Rubik;
                font-size: 22px;
                color: #2E384D;
                `

export const overTimeChartShape = PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    date: PropTypes.any
}))


OverTimeChart.propTypes = {
    data: overTimeChartShape,
    color: PropTypes.string,
    selectedEmotions: PropTypes.array
}
